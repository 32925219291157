<script setup lang="ts">
import { computed, ref } from "vue"
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid"
import { ulid } from "ulid"
import EyeIcon from "@/assets/icons/eye.svg?component"
import EyeIconDisabled from "@/assets/icons/eye-disabled.svg?component"
import InfoCircleIcon from "@/assets/icons/info-circle-solid.svg?component"

interface Props {
  modelValue?: string
  autocomplete?:
    | "off"
    | "on"
    | "name"
    | "email"
    | "new-password"
    | "current-password"
    | "organization"
    | "one-time-code"
  background?: "slate" | "white" | undefined
  description?: string | undefined // hint text
  disabled?: boolean
  errorMessage?: string | undefined
  invalid?: boolean
  groupPosition?: "left" | "middle" | "right" | undefined
  label?: string | undefined
  name?: string | undefined
  optional?: boolean
  placeholder?: string
  size?: "small" | "medium" | "large"
  spellcheck?: boolean // optional spellcheck attribute
  tooltip?: string | undefined // adds tooltip to description
  type?: "text" | "number" | "email" | "password" | "search" | "url"
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: "",
  autocomplete: "off",
  background: "slate",
  description: undefined,
  disabled: false,
  errorMessage: undefined,
  invalid: false,
  groupPosition: undefined,
  label: undefined,
  name: undefined,
  optional: false,
  placeholder: "",
  size: "medium",
  spellcheck: false,
  tooltip: undefined,
  type: "text",
})

const emit = defineEmits(["blur", "update:modelValue"])

defineExpose({ focus })

const sizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "h-6 px-2 py-0 text-xs",
  medium: "h-8 px-3 py-0 text-xs",
  large: "h-12 px-3 py-0 text-m",
}

const wrapperSizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "",
  medium: "",
  large: "",
}

const backgroundClasses: { [K in NonNullable<Props["background"]>]: string } = {
  slate: "bg-slate-50",
  white: "bg-white",
}

const groupPositionClasses: {
  [K in NonNullable<Props["groupPosition"]>]: string
} = {
  left: "rounded-l border-r-0",
  middle: "rounded-none border-x-0",
  right: "rounded-r border-l-0",
}

const input = ref<HTMLInputElement | null>(null)
const inputType = ref(props.type)
const uid = ref(ulid())

const classes = computed(() => {
  const classes = [sizeClasses[props.size]]
  if (props.disabled) {
    classes.push("cursor-not-allowed focus:outline-none placeholder:opacity-30")
  }
  if (props.groupPosition) {
    classes.push(groupPositionClasses[props.groupPosition])
  } else {
    classes.push("rounded focus:ring-inset focus:ring-2")
  }
  if (hasIcon.value) {
    classes.push("pl-8")
  }
  if (props.type === "password") {
    classes.push("pr-9")
  }
  if (props.invalid) {
    classes.push("!border border-red-500 bg-red-25")
  }
  classes.push(backgroundClasses[props.background || "slate"])
  return classes.join(" ")
})

const hasIcon = computed(() => props.type === "search")

const inputWrapperClasses = computed(() => {
  const label = props.label ? "mt-1" : ""
  const size = wrapperSizeClasses[props.size]
  return [label, size].join(" ")
})

const showPassword = computed<boolean>(
  () => props.type === "password" && inputType.value === "text"
)

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit("update:modelValue", value)
  },
})

// focus the native input field
function focus() {
  input.value?.focus()
}

function togglePasswordVisibility() {
  inputType.value = inputType.value === "password" ? "text" : "password"
}

function blur() {
  emit("blur")
}
</script>

<template>
  <div class="relative">
    <label
      v-if="label"
      :for="uid"
      class="block text-sm font-medium text-slate-600"
      :class="{ 'opacity-30': props.disabled }"
    >
      {{ label }}
      <span
        v-if="optional"
        class="text-xxs font-semibold text-slate-300 uppercase"
        >Optional</span
      >
    </label>
    <p
      v-if="description"
      class="text-sm text-slate-400 mb-2"
      :class="{ 'opacity-30': props.disabled }"
    >
      {{ description }}
      <VTooltip v-if="tooltip" :text="props.tooltip">
        <InfoCircleIcon
          class="w-3 h-3 inline text-grey-400"
          aria-hidden="true"
        />
      </VTooltip>
    </p>
    <div class="relative" :class="inputWrapperClasses">
      <div
        v-if="hasIcon"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        :class="{ 'opacity-30': props.disabled }"
      >
        <MagnifyingGlassIcon
          class="h-4 w-4 text-slate-400"
          aria-hidden="true"
        />
      </div>
      <div
        v-if="props.type === 'password'"
        class="password-toggle cursor-pointer absolute inset-y-0 right-0 flex items-center px-3 text-slate-400"
        :class="{ 'opacity-30': props.disabled }"
        @click="togglePasswordVisibility"
      >
        <EyeIcon v-if="showPassword" class="h-4 w-4" aria-hidden="true" />
        <EyeIconDisabled v-else class="h-4 w-4" aria-hidden="true" />
      </div>
      <input
        :id="uid"
        ref="input"
        v-model.trim="value"
        :name="name"
        :type="inputType"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="props.disabled"
        :spellcheck="spellcheck"
        :class="classes"
        class="block w-full text-slate-600 font-medium border-slate-200 focus:ring-indigo-500 placeholder:text-slate-400 focus:border-transparent"
        @blur="blur"
      />
    </div>
    <div class="absolute -bottom-5 text-xs text-red-500 font-medium">
      {{ errorMessage }}
    </div>
  </div>
</template>
