import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "m19.65 9.164.19.294a1 1 0 0 1 .004 1.078c-6.333 9.85-13.154 9.95-19.493.3l-.192-.295a1 1 0 0 1 0-1.083C6.49-.387 13.31-.485 19.65 9.164m-17.44.814-.014.023C7.546 18 12.455 18 17.805 10 12.459 2.007 7.555 2 2.21 9.978M10 6a4 4 0 1 1 0 8 4 4 0 0 1 0-8m0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }