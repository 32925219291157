<script setup lang="ts">
interface Props {
  text: string
}

const props = withDefaults(defineProps<Props>(), {
  text: "",
})
</script>

<template>
  <div class="cursor-pointer inline group" :title="props.text">
    <slot />
  </div>
</template>
