import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "m5.157 16.228-1.146 1.986 1.732 1 1.127-1.952c4.35 2.055 8.77-.186 12.974-6.726a1 1 0 0 0-.004-1.078l-.19-.294c-1.62-2.465-3.271-4.294-4.937-5.487l1.537-2.663-1.732-1-1.533 2.656C8.68.732 4.315 2.995.16 9.458a1 1 0 0 0-.001 1.083l.192.295c1.577 2.4 3.184 4.198 4.806 5.392m2.707-.69c3.287 1.417 6.524-.43 9.94-5.538-1.385-2.072-2.74-3.607-4.083-4.606l-.978 1.695a4 4 0 0 1-3.893 6.743zm-.725-2.743-.989 1.713c-1.3-1-2.613-2.502-3.954-4.507l.014-.023C5.57 4.963 8.756 3.103 11.987 4.4l-.996 1.725a4 4 0 0 0-3.852 6.672m2.77-.797L10 12a2 2 0 0 0 1.685-3.078zm-.003-3.996-1.683 2.916a2 2 0 0 1 1.684-2.916",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }